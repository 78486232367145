import * as types from '../../actionTypes';

export const setFilterInvoicesSync = payload => ({
  type: types.INVOICES_SYNC.SET_FILTER,
  payload
});

export const resetFilterInvoicesSync = payload => ({
  type: types.INVOICES_SYNC.RESET_FILTER,
  payload
});

// GET
export const getInvoicesSync = payload => {
  return {
    type: types.INVOICES_SYNC.GET_INVOICES_SYNC,
    payload
  };
};
export const getInvoicesSyncSuccess = payload => ({
  type: types.INVOICES_SYNC.GET_INVOICES_SYNC_SUCCESS,
  payload
});
export const getInvoicesSyncFail = payload => ({
  type: types.INVOICES_SYNC.GET_INVOICES_SYNC_FAIL,
  payload
});