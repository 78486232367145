import * as types from '../../actionTypes';

export const setFilterCustomersControl = payload => ({
  type: types.CUSTOMERS_CONTROL.SET_FILTER,
  payload
});

export const resetFilterCustomersControl = payload => ({
  type: types.CUSTOMERS_CONTROL.RESET_FILTER,
  payload
});

// GET
export const getCustomersControl = payload => {
  return {
    type: types.CUSTOMERS_CONTROL.GET_CUSTOMERS_CONTROL,
    payload
  };
};
export const getCustomersControlSuccess = payload => ({
  type: types.CUSTOMERS_CONTROL.GET_CUSTOMERS_CONTROL_SUCCESS,
  payload
});
export const getCustomersControlFail = payload => ({
  type: types.CUSTOMERS_CONTROL.GET_CUSTOMERS_CONTROL_FAIL,
  payload
});