import * as types from '../../actionTypes';

export const setFilterProductsControl = payload => ({
  type: types.PRODUCTS_CONTROL.SET_FILTER,
  payload
});

export const resetFilterProductsControl = payload => ({
  type: types.PRODUCTS_CONTROL.RESET_FILTER,
  payload
});

// GET
export const getProductsControl = payload => {
  return {
    type: types.PRODUCTS_CONTROL.GET_PRODUCTS_CONTROL,
    payload
  };
};
export const getProductsControlSuccess = payload => ({
  type: types.PRODUCTS_CONTROL.GET_PRODUCTS_CONTROL_SUCCESS,
  payload
});
export const getProductsControlFail = payload => ({
  type: types.PRODUCTS_CONTROL.GET_PRODUCTS_CONTROL_FAIL,
  payload
});