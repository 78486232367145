import customersControlServices from '../../../../services/v2/customers-control.services';
import * as actions from '../../../actions';
import * as types from '../../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';


export default function* getCustomersControlSaga() {
  yield takeEvery(types.CUSTOMERS_CONTROL.GET_CUSTOMERS_CONTROL, getCustomersControl);
}

export function* getCustomersControl(action) {
  try {
    const response = yield call(customersControlServices.list, action.payload);
    if (!response.status) {
      yield put(actions.getCustomersControlSuccess(response.data));
    } else {
      yield put(actions.getCustomersControlFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomersControlFail(error));
  }
}
