export default {
  Dashboard: 1,
  Sales: 2,
  SalesList: 3,
  NewSales: 4,
  Invoices: 5,
  OrderList: 6,
  InvoicesList: 7,
  RepeatingInvoices: 8,
  OfferList: 9,
  DraftInvoices: 10,
  Reminders: 11,
  Inkasso: 12,
  Customers: 13,
  Products: 14,
  ProductDetail: 15,
  Reports: 16,
  InvoiceReport: 17,
  ProductSalesReport: 18,
  CustomerInvoiceReport: 19,
  DiscountReport: 20,
  AccountReceivableReport: 21,
  Projects: 22,
  OcrTransactions: 23,
  OcrTransactionsNotMatch: 24,
  Inkasso: 25,
  InkassoNotMatch: 26,
  InkassoInvoice: 27,
  CreditChecking: 28,
  Settings: 29,
  SettingGeneral: 30,
  SettingService: 31,
  SettingEmailForward: 32,
  SettingBanks: 33,
  SettingUsers: 34,
  SettingSubscription: 35,
  SettingAdmin: 36,
  ImportInvoice: 37,
  DistributionSends: 38,
  AdminInvoicesReport: 39,
  AdminCreditCheckedReport: 40,
  AdminSentNoKid: 41,
  AdminKidsReport: 42,
  ImportCustomer: 43,
  ImportProduct: 44,
  AdminInvoicesStatus: 45,
  AdminCompaniesInvoicesStatus: 46,
  AdminSendPostReport: 47,
  AdminInvoiceServicesReport: 48,
  SettingUserInvoiceServices: 49,
  CreditStatistics: 50,
  AvtalegiroStatistics: 51,
  Hyllemester: 52,
  Subscriptions: 53,
  CreditCheck: 54,
  InvoicesControl: 55,
  CustomersControl: 56,
  ProductsControl: 57,
  InvoicesSync: 58
};