export const CUSTOMERS_CONTROL = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',

  GET_CUSTOMERS_CONTROL: 'GET_CUSTOMERS_CONTROL',
  GET_CUSTOMERS_CONTROL_SUCCESS: 'GET_CUSTOMERS_CONTROL_SUCCESS',
  GET_CUSTOMERS_CONTROL_FAIL: 'GET_CUSTOMERS_CONTROL_FAIL',
};


