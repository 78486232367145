export const INVOICES_SYNC = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',

  GET_INVOICES_SYNC: 'GET_INVOICES_SYNC',
  GET_INVOICES_SYNC_SUCCESS: 'GET_INVOICES_SYNC_SUCCESS',
  GET_INVOICES_SYNC_FAIL: 'GET_INVOICES_SYNC_FAIL',
};


