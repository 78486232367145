import * as types from '../../actionTypes';

export const setFilterInvoicesControl = payload => ({
  type: types.INVOICES_CONTROL.SET_FILTER,
  payload
});

export const resetFilterInvoicesControl = payload => ({
  type: types.INVOICES_CONTROL.RESET_FILTER,
  payload
});

// GET
export const getInvoicesControl = payload => {
  return {
    type: types.INVOICES_CONTROL.GET_INVOICES_CONTROL,
    payload
  };
};
export const getInvoicesControlSuccess = payload => ({
  type: types.INVOICES_CONTROL.GET_INVOICES_CONTROL_SUCCESS,
  payload
});
export const getInvoicesControlFail = payload => ({
  type: types.INVOICES_CONTROL.GET_INVOICES_CONTROL_FAIL,
  payload
});