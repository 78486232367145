import { api } from '../../constants/api.constant';
import { APIError } from '../../constants/errorCode/system';
import httpHelper from '../../helpers/httpHelper';
import BaseServices from '../base.services';

export default {
  list: async params => {
    try {
      const query = params ? `?${httpHelper.serialize(params)}` : '';
      const url = api.invoicesSync.list + query;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  syncInvoice: async params => {
    try {
      const url = api.invoicesSync.syncInvoice.replace(':companies_id', params.companies_id).replace(':id', params.id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  cancelSync: async params => {
    try {
      const url = api.invoicesSync.cancelSync.replace(':companies_id', params.companies_id).replace(':id', params.id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
