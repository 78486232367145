import productsControlServices from '../../../../services/v2/products-control.services';
import * as actions from '../../../actions';
import * as types from '../../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';


export default function* getProductsControlSaga() {
  yield takeEvery(types.PRODUCTS_CONTROL.GET_PRODUCTS_CONTROL, getProductsControl);
}

export function* getProductsControl(action) {
  try {
    const response = yield call(productsControlServices.list, action.payload);
    if (!response.status) {
      yield put(actions.getProductsControlSuccess(response.data));
    } else {
      yield put(actions.getProductsControlFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getProductsControlFail(error));
  }
}
