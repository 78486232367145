import * as types from '../../actionTypes';

const resetFilter = {
  uuid: '',
  keyword: '',
  approve: 'unapproved',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15,
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  uuid: '',
  keyword: '',
  approve: 'unapproved',
  filter,
  products: {
    total: 0,
    rows: []
  }
};

const productsControl = (state = initialState, action) => {
  switch (action.type) {
    //loading
    case types.PRODUCTS_CONTROL.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // filter
    case types.PRODUCTS_CONTROL.SET_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case types.PRODUCTS_CONTROL.RESET_FILTER:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // list
    case types.PRODUCTS_CONTROL.GET_PRODUCTS_CONTROL:
      return {
        ...state,
        loading: true
      };
    case types.PRODUCTS_CONTROL.GET_PRODUCTS_CONTROL_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload
      };
    case types.PRODUCTS_CONTROL.GET_PRODUCTS_CONTROL_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default productsControl;
