export const PRODUCTS_CONTROL = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',

  GET_PRODUCTS_CONTROL: 'GET_PRODUCTS_CONTROL',
  GET_PRODUCTS_CONTROL_SUCCESS: 'GET_PRODUCTS_CONTROL_SUCCESS',
  GET_PRODUCTS_CONTROL_FAIL: 'GET_PRODUCTS_CONTROL_FAIL',
};


