import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import accountants from './accountants.reducers';
import adminReport from './admin-report.reducers';
import avtalegiro from './avtalegiro.reducers';
import bankAccounts from './bankAccount.reducers';
import companiesInvoiceServicesExcept from './companies-invoice-services-except.reducers';
import companiesPaymentPackages from './companies-payment-packages.reducers';
import companiesServices from './companies-services.reducers';
import companiesSuspend from './companies-suspend.reducers';
import companiesUsers from './companies-users.reducers';
import company from './company.reducers';
import contracts from './contracts.reducers';
import creditChecking from './credit-checking.reducers';
import customerContacts from './customer-contact.reducers';
import customerDiscounts from './customer-discounts.reducers';
import customerInvoices from './customer-invoices.reducers';
import customerNotes from './customer-notes.reducers';
import customerOrders from './customer-orders.reducers';
import customerOverview from './customer-overview.reducers';
import customer from './customer.reducers';
import emailForward from './email-forward.reducers';
import importLogs from './import-log.reducers';
import imports from './imports.reducers';
import invoiceDistributionReceivers from './invoice-distribution-receivers.reducers';
import invoiceDistributionRuns from './invoice-distribution-run.reducers';
import invoiceDistributions from './invoice-distribution.reducers';
import invoiceDrafts from './invoice-drafts.reducers';
import invoiceTypeSettings from './invoice-type-settings.reducers';
import invoiceBroadcasts from './invoiceBroadcasts.reducers';
import invoiceHistory from './invoiceHistory.reducers';
import invoiceNote from './invoiceNote.reducers';
import invoiceSettings from './invoiceSettings.reducers';
import invoices from './invoices.reducers';
import kraviaInvoices from './kravia-invoices.reducers';
import kraviaTransactions from './kravia-transactions.reducers';
import ocrTransactions from './ocr-transactions.reducers';
import offers from './offers.reducers';
import options from './options.reducers';
import orders from './orders.reducers';
import payments from './payments.reducers';
import products from './products.reducers';
import projects from './projects.reducers';
import reminderSettings from './reminder-settings.reducers';
import reminderInvoice from './reminderInvoice.reducers';
import repeatingInvoices from './repeating-invoice.reducers';
import report from './report.reducers';
import salesMans from './sales-man.reducers';
import salesmanSettings from './salesman-settings.reducers';
import subscriptionSettings from './subscription-settings.reducers';
import system from './system.reducers';
import userInvoiceServices from './user-invoice-services.reducers';
import usersPermission from './users-permission.reducers';
import creditCheck from './v2/credit-check.reducers';
import customersControl from './v2/customers-control.reducers';
import invoicesControl from './v2/invoices-control.reducers';
import invoicesSync from './v2/invoices-sync.reducers';
import productsControl from './v2/products-control.reducers';
import vatCodeSettings from './vat-code-settings.reducers';
import vatSettings from './vat-settings.reducers';

export default combineReducers({
  form: reduxForm,
  system,
  company,
  customer,
  customerOrders,
  customerInvoices,
  customerDiscounts,
  customerNotes,
  options,
  products,
  invoices,
  invoiceNote,
  invoiceHistory,
  bankAccounts,
  reminderInvoice,
  invoiceSettings,
  report,
  invoiceBroadcasts,
  companiesServices,
  repeatingInvoices,
  creditChecking,
  invoiceDrafts,
  invoiceTypeSettings,
  projects,
  companiesUsers,
  emailForward,
  usersPermission,
  customerContacts,
  reminderSettings,
  invoiceDistributions,
  invoiceDistributionRuns,
  invoiceDistributionReceivers,
  salesMans,
  companiesPaymentPackages,
  ocrTransactions,
  payments,
  accountants,
  kraviaTransactions,
  kraviaInvoices,
  salesmanSettings,
  vatSettings,
  vatCodeSettings,
  orders,
  offers,
  importLogs,
  adminReport,
  subscriptionSettings,
  imports,
  companiesSuspend,
  userInvoiceServices,
  customerOverview,
  companiesInvoiceServicesExcept,
  avtalegiro,
  contracts,
  creditCheck,
  invoicesControl,
  customersControl,
  productsControl,
  invoicesSync
});