import invoicesControlServices from '../../../../services/v2/invoices-control.services';
import * as actions from '../../../actions';
import * as types from '../../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';


export default function* getInvoicesControlSaga() {
  yield takeEvery(types.INVOICES_CONTROL.GET_INVOICES_CONTROL, getInvoicesControl);
}

export function* getInvoicesControl(action) {
  try {
    const response = yield call(invoicesControlServices.list, action.payload);
    if (!response.status) {
      yield put(actions.getInvoicesControlSuccess(response.data));
    } else {
      yield put(actions.getInvoicesControlFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoicesControlFail(error));
  }
}
