import { invoiceTypes } from '../../../constants/invoice-types.constants';
import * as types from '../../actionTypes';

const resetFilter = {
  keyword: '',
  approve: 'unapproved',
  type: invoiceTypes.Invoice,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15,
  status: 1,
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  keyword: '',
  approve: 'unapproved',
  type: invoiceTypes.Invoice,
  filter,
  invoices: {
    total: 0,
    rows: []
  }
};

const InvoicesSync = (state = initialState, action) => {
  switch (action.type) {
    //loading
    case types.INVOICES_SYNC.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // filter
    case types.INVOICES_SYNC.SET_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case types.INVOICES_SYNC.RESET_FILTER:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // list
    case types.INVOICES_SYNC.GET_INVOICES_SYNC:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES_SYNC.GET_INVOICES_SYNC_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload
      };
    case types.INVOICES_SYNC.GET_INVOICES_SYNC_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default InvoicesSync;
