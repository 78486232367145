import AuthenticationServices from './authentication.services';

// import { webConfig } from '../configs/system.configs';
require('es6-promise').polyfill();
require('isomorphic-fetch');

const getLanguage = () => {
  return localStorage.getItem('i18nextLng') || 'en';
};

export default {
  // BILAGSKY
  getData: async ({
    url,
    headers = {
      'Content-Type': 'application/json',
      'Lang': getLanguage()
    }
  }) => {
    try {
      return await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer' // no-referrer, *client
      });
    } catch (error) {
      throw error;
    }
  },
  postData: async ({ url, data, headers = {
    'Content-Type': 'application/json',
    'Lang': getLanguage()
  } }) => {
    try {
      return await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data)
      });
    } catch (error) {
      throw error;
    }
  },
  postDataToken: async (url, data) => {
    try {
      const token = AuthenticationServices.getAuthenticateToken();

      // Create an AbortController to handle timeout
      const controller = new AbortController();
      const timeout = setTimeout(() => controller.abort(), 600000); // 600 seconds timeout

      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Lang': getLanguage(),
          'Company': data?.companies_id ?? 0,
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
        signal: controller.signal
      });

      clearTimeout(timeout); // Clear timeout if request completes
      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        throw new Error('Request timeout');
      }
      throw error;
    }
  },
  putData: async (url, data) => {
    try {
      return await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Lang': getLanguage()
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match 'Content-Type' header
      });
    } catch (error) {
      throw error;
    }
  },
  putDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Lang': getLanguage(),
        'Company': data?.companies_id ?? 0,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data)
    });
  },
  putDataTokenForm: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const formData = new FormData();
    // eslint-disable-next-line
    for (const name in data) {
      formData.append(name, data[name]);
    }
    return fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Lang': getLanguage(),
        'Company': data?.companies_id ?? 0,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData
    });
  },
  postDataTokenForm: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const formData = new FormData();
    // eslint-disable-next-line
    for (const name in data) {
      if (Array.isArray(data[name])) {
        for (const index in data[name]) {
          if (name === 'files') {
            formData.append(`files[${index}]`, data[name][index]);
          }
          if (name === 'products') {
            for (const key in data[name][index]) {
              formData.append(`products`, data[name].map(p => JSON.stringify(p)).join('|'));
            }
          }
        }
      } else {
        formData.append(name, data[name]);
      }
    }
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Lang': getLanguage(),
        'Company': data?.companies_id ?? 0,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      enctype: 'multipart/form-data',
      body: formData
    });
  },
  deleteDataToken: async url => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Lang': getLanguage(),
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    });
  },
  getDataToken: async (url, companies_id = 0) => {
    try {
      const token = AuthenticationServices.getAuthenticateToken();
      return fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          'Lang': getLanguage(),
          'Company': companies_id ?? 0,
        },
        redirect: 'follow',
        referrer: 'no-referrer'
      });
    } catch (error) {
      throw error;
    }
  },
  // ORYTON API
  getOrytonDataToken: async (url) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Lang': getLanguage()
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    });
  },
  postOrytonDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data)
    });
  },
  putOrytonDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'PUT',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data)
    });
  },
  deleteOrytonDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'DELETE',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data)
    });
  },
  getDataElma: async organization_number => {
    const url = `https://smp.difi.no/rest/api/participants?query=${organization_number}`
    const basicAuth = Buffer.from(`Tam:eBIYQm7OQKdp9Xesr`).toString('base64');
    return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + basicAuth
      },
    });
  },
  postOrytonDataTokenForm: async (url, data) => {
    const formData = new FormData();
    for (const name in data) {
      if (data[name]) {
        formData.append(name, data[name]);
      }
    }

    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      enctype: 'multipart/form-data',
      body: formData
    });
  },
  postDataTokenFormV2: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const formData = new FormData();
    // eslint-disable-next-line
    for (const name in data) {
      if (Array.isArray(data[name])) {
        for (const item of data[name]) {
          if (name === 'files') {
            formData.append(`files`, item);
          }
        }
        if (name === 'products') {
          formData.append(`products`, JSON.stringify(data[name]));
        }
      } else {
        formData.append(name, data[name]);
      }
    }
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Lang': getLanguage(),
        'Company': data?.companies_id ?? 0,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      enctype: 'multipart/form-data',
      body: formData
    });
  },
  putDataTokenFormV2: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const formData = new FormData();
    // eslint-disable-next-line
    for (const name in data) {
      if (Array.isArray(data[name])) {
        for (const item of data[name]) {
          if (name === 'files') {
            formData.append(`files`, item);
          }
        }
        if (name === 'products') {
          formData.append(`products`, JSON.stringify(data[name]));
        }
      } else {
        formData.append(name, data[name]);
      }
    }
    return fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Lang': getLanguage(),
        'Company': data?.companies_id ?? 0,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData
    });
  },
};
