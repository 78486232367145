export const INVOICES_CONTROL = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',

  GET_INVOICES_CONTROL: 'GET_INVOICES_CONTROL',
  GET_INVOICES_CONTROL_SUCCESS: 'GET_INVOICES_CONTROL_SUCCESS',
  GET_INVOICES_CONTROL_FAIL: 'GET_INVOICES_CONTROL_FAIL',
};


