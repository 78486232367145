export const PrefixUrl = '/:company';


export const CompanyUrl = {
  AddCompany: '/company/add',
  NewCompany: '/company/new',
  NewCompanyOrganization: '/company/new/:id',
}
export const ConfirmUrl = {
  InvoiceConfirm: '/invoice-confirm/:companies_id/:id/:confirm_token',
}
export const CompanySuspendUrl = {
  InvoiceUnpaidList: '/suspend/:companies_id/invoices',
}
export const CustomerOverview = {
  Invoices: '/customer-overview/:token_access',
}
export const PaymentsUrl = {
  Payment: '/payments/:uuid',
  PaymentThankYou: '/payments/:uuid/thank-you',
}
export const MandateUrl = {
  MandateGenerate: '/mandates/:token_access',
}
export const ErrorRoutes = {
  Notfound: '/404',
  BadRequest: '/500'
};
export const RoutesUrl = {
  AddCompany: `/company/add`,
  Dashboard: `${PrefixUrl}/`,
  Sales: `${PrefixUrl}/sales`,
  NewSales: `${PrefixUrl}/invoices/create`,
  NewInvoices: `${PrefixUrl}/invoices/create/:uuid/:invoice_id`,
  CreateInvoice: `${PrefixUrl}/invoices/create`,
  CreateInvoiceUuid: `${PrefixUrl}/invoices/create/:uuid`,
  Invoices: `${PrefixUrl}/sales/invoices`,
  InvoicesDetail: `${PrefixUrl}/sales/invoices/:companies_id/:id`,
  InvoiceDelete: `${PrefixUrl}/sales/invoices/:id`,
  OrderList: `${PrefixUrl}/sales/order-invoices`,
  OrderUpdate: `${PrefixUrl}/invoices/create/:uuid/:invoice_id`,
  RepeatingInvoices: `${PrefixUrl}/sales/repeating-invoices`,
  RepeatingInvoicesUpdate: `${PrefixUrl}/invoices/create/:uuid/:invoice_id`,
  OfferList: `${PrefixUrl}/sales/offers`,
  DraftInvoices: `${PrefixUrl}/sales/drafts`,
  Reminders: `${PrefixUrl}/sales/reminders`,
  DistributionSend: `${PrefixUrl}/distribution-sends`,
  Customers: `${PrefixUrl}/customers`,
  AddCustomer: `${PrefixUrl}/customers/add`,
  UpdateCustomer: `${PrefixUrl}/customers/add/:id`,
  NewCustomer: `${PrefixUrl}/customers/new`,
  CustomerDetail: `${PrefixUrl}/customers/customer-detail/:id`,
  Products: `${PrefixUrl}/products`,
  ProductDetail: `${PrefixUrl}/products/:id`,
  Reports: `${PrefixUrl}/reports`,
  InvoiceReport: `${PrefixUrl}/reports/invoices`,
  ProductSalesReport: `${PrefixUrl}/reports/product-sales`,
  CustomerInvoiceReport: `${PrefixUrl}/reports/customer-invoices`,
  DiscountReport: `${PrefixUrl}/reports/discounts`,
  AccountReceivableReport: `${PrefixUrl}/reports/account-receivable`,
  OcrTransactions: `${PrefixUrl}/ocr-transactions`,
  OcrTransactionsNotMatch: `${PrefixUrl}/ocr-transactions/not-match`,
  OcrMatchingInvoice: `${PrefixUrl}/ocr-transactions/matching-invoice/:id`,
  Inkasso: `${PrefixUrl}/inkasso`,
  InkassoNotMatch: `${PrefixUrl}/inkasso/not-match`,
  InkassoInvoice: `${PrefixUrl}/inkasso/invoices`,
  InkassoInvoiceDetail: `${PrefixUrl}/inkasso/invoices/:id`,
  InkassoDetail: `${PrefixUrl}/inkasso-transaction/:payment_id`,
  CreditChecking: `${PrefixUrl}/credit-checking`,
  CreditCheck: `${PrefixUrl}/credit-check`,
  Projects: `${PrefixUrl}/projects`,
  Settings: `${PrefixUrl}/settings`,
  SettingGeneral: `${PrefixUrl}/setting/general`,
  SettingEmailForward: `${PrefixUrl}/setting/forwards`,
  SettingService: `${PrefixUrl}/setting/services`,
  AdminInvoicesReport: `${PrefixUrl}/admin-reports/invoices`,
  AdminCreditCheckedReport: `${PrefixUrl}/admin-reports/credit-checked`,
  AdminSentNoKid: `${PrefixUrl}/admin-reports/sent-no-kid`,
  AdminKidsReport: `${PrefixUrl}/admin-reports/kids`,
  Unauthorized: `${PrefixUrl}/unauthorized`,
  Error404: `${PrefixUrl}/404`,
  BadRequest: `${PrefixUrl}/500`,
  ImportInvoice: `${PrefixUrl}/import-invoice`,
  ImportCustomer: `${PrefixUrl}/import-customer`,
  ImportProduct: `${PrefixUrl}/import-product`,
  AdminCompaniesInvoicesStatus: `${PrefixUrl}/admin-reports/companies-invoices-status`,
  AdminCompaniesInvoicesStatusList: `${PrefixUrl}/admin-reports/companies-invoices-status/invoices`,
  AdminInvoiceSendPost: `${PrefixUrl}/admin-reports/invoice-send-post`,
  AdminInvoiceServices: `${PrefixUrl}/admin-reports/invoice-services`,
  SettingUserInvoiceServices: `${PrefixUrl}/setting/user-invoice-services`,
  CreditStatistics: `${PrefixUrl}/admin-reports/credit-statistics`,
  CreditStatisticsList: `${PrefixUrl}/admin-reports/credit-statistics/:companies_id`,
  AvtalegiroStatistics: `${PrefixUrl}/admin-reports/avtalegiro-statistics`,
  AvtalegiroStatisticsList: `${PrefixUrl}/admin-reports/avtalegiro-statistics/:companies_id`,
  Hyllemester: `${PrefixUrl}/contracts`,
  Subscriptions: `${PrefixUrl}/subscriptions`,
  BislabTermConditions: `${PrefixUrl}/bislab-term-conditions`,
  InvoicesControl: `${PrefixUrl}/control/invoices`,
  CustomersControl: `${PrefixUrl}/control/customers`,
  ProductsControl: `${PrefixUrl}/control/products`,
  InvoicesSync: `${PrefixUrl}/invoices-sync`,
};


export const GeneralRoutes = {
  SettingGeneral: `${PrefixUrl}/setting/general`,
  SettingBanks: `${PrefixUrl}/setting/general/banks`,
  SettingUsers: `${PrefixUrl}/setting/general/users`,
  SettingSubscription: `${PrefixUrl}/setting/general/subscription`,
  SettingAdmin: `${PrefixUrl}/setting/general/admin`,
  Unauthorized: `${PrefixUrl}/unauthorized`,
  Error404: `${PrefixUrl}/404`,
  BadRequest: `${PrefixUrl}/500`,
}
