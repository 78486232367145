import invoicesSyncServices from '../../../../services/v2/invoices-sync.services';
import * as actions from '../../../actions';
import * as types from '../../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';


export default function* getInvoicesSyncSaga() {
  yield takeEvery(types.INVOICES_SYNC.GET_INVOICES_SYNC, getInvoicesSync);
}

export function* getInvoicesSync(action) {
  try {
    const response = yield call(invoicesSyncServices.list, action.payload);
    if (!response.status) {
      yield put(actions.getInvoicesSyncSuccess(response.data));
    } else {
      yield put(actions.getInvoicesSyncFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoicesSyncFail(error));
  }
}
